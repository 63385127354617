import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SafeHtmlPipe } from './safeHtml/safehtml';
import { SafeUrlPipe } from './safeUrl/safeurl';
import { LayoutFieldColumnSort } from './layoutFieldColumnSort.pipe';
import { OrderByPosition } from './orderByPosition.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { TypeofPipe } from './typeOf/typeof.pipe';
import { CustomDateStringPipe } from './customDateString/customDateString.pipe';
import { FilterSignContactsByEmailPipe } from './filterSignContactsByEmail/filterSignContactsByEmail.pipe';
import { HighlightMatchingTextPipe } from './highlightMatchingText/highlightMatchingText.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    SafeHtmlPipe, 
    SafeUrlPipe, 
    LayoutFieldColumnSort, 
    OrderByPosition, 
    EllipsisPipe, 
    TypeofPipe, 
    CustomDateStringPipe, 
    FilterSignContactsByEmailPipe,
    HighlightMatchingTextPipe
  ],
  exports: [
    SafeHtmlPipe, 
    SafeUrlPipe, 
    LayoutFieldColumnSort, 
    OrderByPosition, 
    EllipsisPipe, 
    TypeofPipe, 
    CustomDateStringPipe, 
    FilterSignContactsByEmailPipe,
    HighlightMatchingTextPipe
  ]
})
export class PipesModule { }
