import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { CredentialingItemMessage } from '@app/models';

export interface RequiredItemMessageApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CredentialingRequiredItemMessagesService {
  private currentRequiredItem;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/credentialing-required-item-messages/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_requiredItem): void {
    this.currentRequiredItem = _requiredItem;
  }

  getCurrent() {
    return this.currentRequiredItem;
  }

  getAll(): Observable<CredentialingItemMessage[]> {
    return this.httpClient.get<CredentialingItemMessage[]>(this.API_URL);
  }


  getById(id: string): Observable<CredentialingItemMessage> {
    return this.httpClient.get<CredentialingItemMessage>(this.API_URL + id);
  }


  create(_itemMessage: CredentialingItemMessage): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_itemMessage), this.headerOptions);
  }


  markMessagesViewedBy(_ids: string[], _viewerType: string): Observable<any[]> {
    const reqObject = { ids: _ids, viewer: _viewerType}; // Expecting { ids: [], viewer: 'provider' | 'administrator' }
    return this.httpClient.post<any[]>(this.API_URL + 'markMessagesViewedBy', JSON.stringify(reqObject), this.headerOptions);
  }


  update(_itemMessage: CredentialingItemMessage): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _itemMessage._id, JSON.stringify(_itemMessage), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + id);
  }


  selectAllSearch(searchTerms): Observable<CredentialingItemMessage[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<CredentialingItemMessage[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<RequiredItemMessageApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<RequiredItemMessageApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }
}