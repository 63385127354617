export class CalendarEvent {
    _id: string;
    user: any;
    client: any;
    calendarGroup: any;
    title: string;
    details: string;
    absence_reason: string;
    absence_communication: string;
    color: any;
    start: Date;
    end: Date;
    endDateCopy: Date; // front end field that allows copying event over multiple days
    startTime: string;
    endTime: string;
    date_created: Date;
    company_holiday: boolean;
    disabled_campaigns_on_day: boolean;
    public: boolean;
    is_all_day: boolean;
    deleted: boolean;
    related_pto_request_id: string;
    module_record_id: string;
    module_type: string;
    startRange;

    relatedModuleRecord: any; // Virtual field created from module_type and module_record_id;
    related_record: any; // Used for mock pto request events in calendar functionality.

    // For filters
    selectedAbsenceCommunication: any;
    dateCreatedRange: any;
    dateCreated;
    dateCreatedEnd;
    hasSearchTerms;
}
