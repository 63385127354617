import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { YeehroSignContact } from '@app/models';

export interface YeehroSignContactApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class YeehroSignContactService {
  private currentYeehroSignContact;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/yeehro-sign-contacts/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_signContact): void {
    this.currentYeehroSignContact = _signContact;
  }

  getCurrent() {
    return this.currentYeehroSignContact;
  }

  getAll(): Observable<YeehroSignContact[]> {
    return this.httpClient.get<YeehroSignContact[]>(this.API_URL);
  }


  getById(id: string): Observable<YeehroSignContact> {
    return this.httpClient.get<YeehroSignContact>(this.API_URL + id);
  }


  create(_signContact: YeehroSignContact): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_signContact), this.headerOptions);
  }


  update(_signContact: YeehroSignContact): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _signContact._id, JSON.stringify(_signContact), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + id);
  }


  selectAllSearch(searchTerms): Observable<YeehroSignContact[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<YeehroSignContact[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<YeehroSignContactApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<YeehroSignContactApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }
}