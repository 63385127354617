
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/models';

import { GenericModuleService } from './generic-module.service';
import { SharedUtilsService } from './shared-utils.service';

export interface UserPrivileges {
  gridViewPrivilege: string;
  createPrivilege: boolean;
  massUpdatePrivilege: boolean;
  deletionLevel: string;
  exportPrivilege: boolean;
}

@Injectable({ providedIn: 'root' })
export class RoleAccessService {
  public userAccessControls = null;

  viewAccessLevel: boolean = null;

  constructor(
    private router: Router,
    private _genericService: GenericModuleService,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrentAccessLevels(_accessControls) {
    this._sharedUtilsService.saveInLocal('user-access-controls', _accessControls);
  }


  getCurrentAccessLevels(): Promise<any[]> {
    return new Promise(async(resolve) => {

      if (!this.userAccessControls) {
        const existingControls = this._sharedUtilsService.getFromLocal('user-access-controls');
        this.userAccessControls = (existingControls) ? existingControls.value : null;
        // console.log("User Access Controls: ", this.userAccessControls);

        if (!this.userAccessControls) {
          this.userAccessControls = existingControls;
          // console.log("User Access Controls after correcting the value mess: ", this.userAccessControls);
        }
      }

      resolve(this.userAccessControls);
    });
  }


  getUserPrivileges(_user: User, _moduleName: string): Promise<UserPrivileges> {
    return new Promise(async(resolve) => {
      const modulePriviliges: UserPrivileges = {
        gridViewPrivilege: 'All',
        createPrivilege: false, // boolean
        massUpdatePrivilege: false, // boolean
        deletionLevel: 'None', // string
        exportPrivilege: false
      };

      if (_user && _user.userType) {
        if (_user.userType === 'System Admin') {
          modulePriviliges.createPrivilege = true;
          modulePriviliges.massUpdatePrivilege = true;
          modulePriviliges.deletionLevel = 'All';
          modulePriviliges.exportPrivilege = true;
          modulePriviliges.gridViewPrivilege = 'All';
        } else {
          modulePriviliges.createPrivilege = await this.checkForAccessCreatePrivilege(_moduleName);
          modulePriviliges.massUpdatePrivilege = await this.checkForAccessMassUpdatePrivilege(_moduleName);
          modulePriviliges.deletionLevel = await this.getDeletionLevelAccess(_moduleName);
          modulePriviliges.exportPrivilege = await this.getExportLevelAccess(_moduleName);
          modulePriviliges.gridViewPrivilege = await this.getAccessLevel(_moduleName, 'view');
        }
      }
  
      resolve(modulePriviliges);
    });
  }


  async getAccessLevel(_moduleName, _view) {
    // console.log('Checking for module: ', _moduleName);
    // console.log('View: ', _view);
    // console.log('userAccessControls: ', this.userAccessControls);

    const accessControls = await this.getCurrentAccessLevels();
    // console.log('accessControls: ', accessControls);

    const moduleToCheck = (accessControls) ? accessControls.find(_uac => _uac.name === _moduleName && _uac.views[_view]) : null;
    // console.log('Found control settings for module and view: ', moduleToCheck);

    return (moduleToCheck) ? moduleToCheck.views[_view] : null;
  }


  async getModuleAccessInfo(_moduleName) {
    // console.log('Checking for module: ', _moduleName);
    // console.log('View: ', _view);
    // console.log('userAccessControls: ', this.userAccessControls);

    const accessControls = await this.getCurrentAccessLevels();

    // console.log('accessControls: ', accessControls);

    const moduleToCheck = (accessControls) ? accessControls.find(_uac => _uac.name === _moduleName) : null;
    // console.log('Found control settings for module and view: ', moduleToCheck);

    return moduleToCheck;
  }


  getModuleAccessInfoPromise(_moduleName): Promise<any> {
    return new Promise(async(resolve) => {
      // console.log('Checking for module: ', _moduleName);
      // console.log('View: ', _view);
      // console.log('userAccessControls: ', this.userAccessControls);

      const accessControls = await this.getCurrentAccessLevels();

      // console.log('accessControls: ', accessControls);

      const moduleToCheck = (accessControls) ? accessControls.find(_uac => _uac.name === _moduleName) : null;
      // console.log('Found control settings for module and view: ', moduleToCheck);

      resolve(moduleToCheck);
    });
  }


  checkForAccess(_moduleName, _errorLabel): Promise<boolean> {
    return new Promise(async(resolve) => {
      // console.log('_moduleName: ', _moduleName);
      // console.log('_errorLabel: ', _errorLabel);

      const viewAccessLevel = await this.getAccessLevel(_moduleName, 'access');
      // console.log('View Access Level: ', viewAccessLevel);

      if (viewAccessLevel && (viewAccessLevel == "true" || viewAccessLevel == "Enabled") ) {
        resolve(true);
      } else {
        this._sharedUtilsService.showErrorDialog(`You do not have access to ${_errorLabel}. Please contact an administrator.`);
        this.router.navigate(['/app/dashboard']);
      }

      resolve(false);
    });
  }


  // _includedTerms is an optional array of additional tearms to add to search ...eg: teams
  checkGridAccessDefaultParams(_moduleName, _searchTerms, _currentUser, _includedTerms?): Promise<object> {
    return new Promise(async(resolve) => {
      const viewLevel = await this.getAccessLevel(_moduleName, 'view');
      // console.log("🚀 ~ file: auth.service.ts ~ line 181 ~ AuthService ~ returnnewPromise ~ viewLevel", viewLevel);

      if (viewLevel && viewLevel === 'Owner') {
        if (_searchTerms['inclusionSearch']) _searchTerms['inclusionSearch']['assigned_to'] = {inclusionType: 'Include', value: [_currentUser._id]};
        _searchTerms['assigned_to'] = [_currentUser._id];
      }

      // Checking if there are more terms to add to search
      if (_includedTerms && _includedTerms !== undefined) {
        if (_currentUser.userType != 'System Admin' && _includedTerms.includes('teams')) {
          const teamsToSearch = await this._genericService.getTeamsForSearch(_currentUser); // Looks at users teams and if not set adds the global team to search

          if (_searchTerms['inclusionSearch']) _searchTerms['inclusionSearch']['teams'] = {inclusionType: 'Include', value: teamsToSearch};
          _searchTerms['teams'] = teamsToSearch;
        }
      }

      resolve(_searchTerms);
    });
  }


  getTeamIdsForUser(_currentUser): Promise<any> {
    return new Promise(async(resolve) => {
      const usersAllowedTeams = await this._genericService.getTeamsForSearch(_currentUser);

      const teamIds = (usersAllowedTeams && usersAllowedTeams.length) ? usersAllowedTeams.map(_t => (_t && _t._id) ? _t._id : _t) : null;
      resolve(teamIds);
    });
  }


  enforceTeamsSearch(_searchTerms, _currentUser): Promise<any> {
    return new Promise(async(resolve) => {
      // console.log('_searchTerms: ', _searchTerms);
      
      const usersAllowedTeams = await this._genericService.getTeamsForSearch(_currentUser); // Looks at users teams and if not set adds the global team to search
      // console.log('Users allowed teams: ', usersAllowedTeams);

      if (_searchTerms.teams) {
        // console.log('Teams is set for non system admin. Make sure they have teams set if no current teams search term and limit only those they belong to.');
        // console.log('_searchTerms.teams: ', _searchTerms.teams);

        let hasUnallowedTeams = false;

        if (_searchTerms['inclusionSearch'] && _searchTerms['inclusionSearch']['teams']) {
          const teamsTerms = _searchTerms['inclusionSearch']['teams'].value;

          teamsTerms.map(_tt => (_tt && _tt._id) ? _tt._id : _tt).forEach(_tid => {
            if (!hasUnallowedTeams && !usersAllowedTeams.find(_at => _at._id == _tid)) {
              // console.log('A team was set that this user does not have access to.');
              this._sharedUtilsService.showMintNotificationWithOptions('Teams were set that you do not have access to. They will be removed from search terms', 3000, 'danger');
              hasUnallowedTeams = true;
            }
          });

          _searchTerms['inclusionSearch']['teams'].value = teamsTerms.filter(_vid => {
            const teamId = (_vid && _vid._id) ? _vid._id : _vid;
            // console.log('Looking for team with id of: ', _vid);
            return usersAllowedTeams.findIndex(_t => _t._id == teamId) !== -1;
          });

          _searchTerms['teams'] = _searchTerms['inclusionSearch']['teams'].value;

          // console.log('inclusion teams after filtering: ', _searchTerms['inclusionSearch']['teams']);
        } else {
          // console.log('Now doing teams:');
            
          _searchTerms['teams'].map(_tt => (_tt && _tt._id) ? _tt._id : _tt).forEach(_tid => {
            if (!hasUnallowedTeams && !usersAllowedTeams.find(_at => _at._id == _tid)) {
              // console.log('A team was set that this user does not have access to.');
              this._sharedUtilsService.showMintNotificationWithOptions('Teams were set that you do not have access to. They will be removed from search terms', 3000, 'danger');
              hasUnallowedTeams = true;
            }
          });

          _searchTerms['teams'] = usersAllowedTeams.filter(_vid => {
            const teamId = (_vid && _vid._id) ? _vid._id : _vid;
            // return usersAllowedTeams.find(_t => _t._id === teamId);
            return usersAllowedTeams.findIndex(_t => _t._id == teamId) !== -1;
          });

          // console.log('Teams after filtering: ', _searchTerms['teams']);
        }

        // _searchTerms['teams'] = _searchTerms['inclusionSearch']['teams'].value;
      } 
      
      // console.log('_searchTerms.teams: ', _searchTerms.teams);

      if (!_searchTerms.teams || _searchTerms.teams == undefined || !_searchTerms.teams.length) { 
        // console.log('No teams terms set. Adding all this user is added to.');
        _searchTerms['teams'] = usersAllowedTeams;
      }

      if (!_searchTerms['inclusionSearch']['teams'] || !_searchTerms['inclusionSearch']['teams'].value || _searchTerms['inclusionSearch']['teams'].value == undefined || !_searchTerms['inclusionSearch']['teams'].value.length) { 
        // console.log('No inclusion teams terms set. Adding all this user is added to.');
        _searchTerms['inclusionSearch']['teams'] = {inclusionType: 'Include', value: usersAllowedTeams};
      }

      // console.log("Search Terms after everything: ", _searchTerms);

      resolve(null);
    });
  }


  // For checking if they have access with no redirect.
  checkForAccessPrivilege(_moduleName): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewAccessLevel = await this.getAccessLevel(_moduleName, 'access');
      // console.log('View Access Level: ', viewAccessLevel);

      if (viewAccessLevel && (viewAccessLevel == "true" || viewAccessLevel == "Enabled")) resolve(true);

      resolve(false);
    });
  }


  checkForAccessCreatePrivilege(_moduleName): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewCreateLevel = await this.getAccessLevel(_moduleName, 'create');
      // console.log('View Create Level: ', viewCreateLevel);

      if (viewCreateLevel && (viewCreateLevel == "true" || viewCreateLevel == "Enabled")) resolve(true);

      resolve(false);
    });
  }


  checkForAccessMassUpdatePrivilege(_moduleName): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewMassUpdateLevel = await this.getAccessLevel(_moduleName, 'mass_update');
      // console.log('View Mass Update Level: ', viewMassUpdateLevel);

      if (viewMassUpdateLevel && (viewMassUpdateLevel == "true" || viewMassUpdateLevel == "Enabled")) resolve(true);

      resolve(false);
    });
  }


  getDeletionLevelAccess(_moduleName): Promise<string> {
    return new Promise(async(resolve) => {
      const viewDeleteLevel = await this.getAccessLevel(_moduleName, 'delete');
      // console.log('View Delete Level: ', viewDeleteLevel);

      const _deleteLevel = (viewDeleteLevel && viewDeleteLevel !== undefined) ? viewDeleteLevel : 'None';

      resolve(_deleteLevel);
    });
  }


  getExportLevelAccess(_moduleName): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewMassUpdateLevel = await this.getAccessLevel(_moduleName, 'export');
      // console.log('View Mass Update Level: ', viewMassUpdateLevel);

      if (viewMassUpdateLevel && (viewMassUpdateLevel == "true" || viewMassUpdateLevel == "Enabled")) resolve(true);

      resolve(false);
    });
  }


  checkForItemDeletionAccessPrivilege(_moduleName, _currentItemBeingviewed, _currentUser): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewMassUpdateLevel = await this.getAccessLevel(_moduleName, 'delete');
      // console.log('View Mass Update Level: ', viewMassUpdateLevel);

      if (viewMassUpdateLevel && viewMassUpdateLevel == "All") {
        resolve(true);
      } else if (viewMassUpdateLevel && viewMassUpdateLevel == "Owner") {
        if (_currentItemBeingviewed.assigned_to && _currentItemBeingviewed.assigned_to !== undefined) {
          const assigned_to = (_currentItemBeingviewed.assigned_to && _currentItemBeingviewed.assigned_to._id) ? _currentItemBeingviewed.assigned_to._id : _currentItemBeingviewed.assigned_to;
          if (assigned_to === _currentUser._id) resolve(true);
        }
      }

      resolve(false);
    });
  }


  getEditLevelAccess(_moduleName): Promise<string> {
    return new Promise(async(resolve) => {
      const viewDeleteLevel = await this.getAccessLevel(_moduleName, 'edit');
      // console.log('View Delete Level: ', viewDeleteLevel);

      const _deleteLevel = (viewDeleteLevel && viewDeleteLevel !== undefined) ? viewDeleteLevel : 'None';

      resolve(_deleteLevel);
    });
  }


  checkForItemEditAccessPrivilege(_moduleName, _currentItemBeingviewed, _currentUser): Promise<boolean> {
    return new Promise(async(resolve) => {
      const viewMassUpdateLevel = await this.getAccessLevel(_moduleName, 'edit');
      // console.log('View Mass Update Level: ', viewMassUpdateLevel);

      if (viewMassUpdateLevel && viewMassUpdateLevel == "All") {
        resolve(true);
      } else if (viewMassUpdateLevel && viewMassUpdateLevel == "Owner") {
        if (_currentItemBeingviewed.assigned_to && _currentItemBeingviewed.assigned_to !== undefined) {
          const assigned_to = (_currentItemBeingviewed.assigned_to && _currentItemBeingviewed.assigned_to._id) ? _currentItemBeingviewed.assigned_to._id : _currentItemBeingviewed.assigned_to;
          if (assigned_to === _currentUser._id) resolve(true);
        }
      }

      resolve(false);
    });
  }


  getUsersAllowedModules(_currentUser): Promise<any[]> {
    // console.log("Current User: ", _currentUser);
    return new Promise(async(resolve) => {
      let modulesForRoles = [];

      if (_currentUser && _currentUser.userType !== 'System Admin') {
        const roles = (_currentUser && _currentUser.roles) ? _currentUser.roles : [];
        // console.log('Roles: ', roles);

        roles.filter(_r => _r.moduleAccessControls).forEach(_role => {
           // check each control for access view
          _role.moduleAccessControls.filter(_mac => _mac && _mac.ssModule).forEach(_mac => {
            // console.log('_mac: ', _mac);
            const macViews = (_mac.views) ? _mac.views : null;
            if (macViews != undefined && macViews['access'] != undefined && (macViews['access'] === "Enabled" || macViews['access'] === "true") && _mac.ssModule) {
              modulesForRoles.push(_mac.ssModule);
            }
          });
        });
      }

      resolve(modulesForRoles);
    });
  }


  getNonRestrictedSectionFields(_sectionFields: any[], _moduleName: string): Promise<any[]> {
    return new Promise(async(resolve) => {
      const nonRestrictedModuleFields = [];

      if (_sectionFields && _sectionFields.length && _moduleName) {
        const modulePermissions = await this.getModuleAccessInfoPromise(_moduleName);
        // console.log('modulePermissions: ', modulePermissions);

        // See if modulePermissions.fields is undefined and if Object.keys(modulePermissions.fields).length.
        // Sometimes in a few smaller modules the fields are not null but it an empty object.
        const fieldPermissions = (modulePermissions && modulePermissions.fields && Object.keys(modulePermissions.fields).length) ? modulePermissions.fields : null;
        // console.log('fieldPermissions: ', fieldPermissions);

        // If field permissions, loop through and push any field that does not have a permission set or is not set to 'none'.
        if (fieldPermissions != undefined) {
          for (let index = 0; index < _sectionFields.length; index++) {
            const _f = _sectionFields[index];

            // If field is sub-string do permission checks here, else if regular field see if the permission exists and is not set to 'none'. 
            if (_f.fieldName.includes('.')) {
              const firstStringPart = _f.fieldName.substring(0, _f.fieldName.indexOf('.'));
              // console.log("firstStringPart:", firstStringPart)

              if (firstStringPart != undefined && (fieldPermissions[firstStringPart] == undefined || fieldPermissions[firstStringPart].toLowerCase() !== 'none')) {
                nonRestrictedModuleFields.push(_f);
              }
            } else if (fieldPermissions[_f.fieldName] == undefined || fieldPermissions[_f.fieldName].toLowerCase() !== 'none') {
              nonRestrictedModuleFields.push(_f);
            }
          }
        }
      }

      resolve(nonRestrictedModuleFields);
    });
  }


  removeRestrictedSectionFields(_sectionFields: any[], _moduleName) {
    return new Promise(async(resolve) => {
      if (_sectionFields && _sectionFields.length && _moduleName) {
        const modulePermissions = await this.getModuleAccessInfoPromise(_moduleName);
        // console.log('modulePermissions: ', modulePermissions);

        // See if modulePermissions.fields is undefined and if Object.keys(modulePermissions.fields).length.
        // Sometimes in a few smaller modules the fields are not null but it an empty object.
        const fieldPermissions = (modulePermissions != undefined && modulePermissions.fields != undefined && Object.keys(modulePermissions.fields).length) ? modulePermissions.fields : null;
        // console.log('fieldPermissions: ', fieldPermissions);

        // If field permissions, loop through and splice any field that does not have a permission set or is not set to 'none'.
        if (fieldPermissions != undefined) {
          for (let index = 0; index < _sectionFields.length; index++) {
            const _f = _sectionFields[index];
            // console.log("Field: ", _f);
            // console.log("Field Permission: ", fieldPermissions[_f.fieldName]);

            // If normal field see if there is a permission set and not set to 'none'. Else if field is sub-string do same checks in that condition.
            if (fieldPermissions[_f.fieldName] != undefined && fieldPermissions[_f.fieldName].toLowerCase() === 'none') {
              _sectionFields.splice(index, 1);
            } else if (_f.fieldName.includes('.')) {
              const firstStringPart = _f.fieldName.substring(0, _f.fieldName.indexOf('.'));
              //console.log("firstStringPart:", firstStringPart)

              if (firstStringPart != undefined && fieldPermissions[firstStringPart] != undefined && fieldPermissions[firstStringPart].toLowerCase() === 'none') {
                _sectionFields.splice(index, 1);
              }
            }
          }
        }
      }

      resolve(null);
    });
  }


  getFirstPartOfSubString(_fieldName) {
    // console.log('Getting first part of sub-string: ', _fieldName);
    return _fieldName.substring(0, _fieldName.indexOf('.'));
  }
}