import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { AuditLog, LayoutField } from '@app/models';
import { AuditLogService, DropdownService, SharedUtilsService } from '@app/services';

export interface CredentialingSetupsApi {
  items: any[];
  total_count: number;
}


export class CredentialingChecklistItem {
  _id;
  ids;
  category: string;
  label: string;
  description: string;
  field_type: string;
  provider_type: string;
  expiration_date: Date;
  field_options: any;
  provider_assigned: boolean;
  completed: boolean;
  status: string; // Approved, Not Approved
  unreadMessageCount: number; // Used for display only and is different depending on the authorType.
  messages: any; // Is now a virtual CredentialingItemMessage array.
  completionDetails: {
    actionValue: any,
    documentLink: any,
    approvalStatus: string,
    expirationDate: Date,
    notes: string,
    dateCompleted: Date
  };
  activities: any;
  relatedCredentialingRecord: any; // Relate to the credentialing.
  isUnsaved: boolean; // Used for credentialing-setup-checklist.
  name: string; // Used on ones in db.
  deleted: boolean; // Used on ones in db.
}

@Injectable({ providedIn: 'root' })
export class CredentialingSetupsService {
  private currentSub;

  public currentSearch = {
    params: null,
    results: null
  };

  public field_types = ['Yeehro Sign Document', 'Uploaded Document', 'Todo Item', 'Date', 'Library Resource'];
  private API_URL = '/api/credentialing-setups/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  public subStatuses = null;
  private credentialing_required_items_categories = null;

  constructor(
    private httpClient: HttpClient,
    private _auditService: AuditLogService,
    private _sharedUtilsService: SharedUtilsService,
    private _dropdownService: DropdownService
  ) { }

  setCurrent(_sub): void {
    this.currentSub = _sub;
  }

  getCurrent() {
    return this.currentSub;
  }


  create(_sub): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_sub), this.headerOptions);
  }


  getCredentialingItemCategories(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {

      if (!this.credentialing_required_items_categories) {
        const dropdown = await lastValueFrom(this._dropdownService.getByName({ name: 'credentialing_required_items_categories' }));
        // console.log('dropdown: ', dropdown);
        const dropdownOptions = (dropdown && dropdown['options']) ? dropdown['options'] : [];
        // console.log('dropdownOptions: ', dropdownOptions);

        this.credentialing_required_items_categories = dropdownOptions
          .sort(
            (a, b) =>
              ((a.value && b.value) && (a.value.toLowerCase() > b.value.toLowerCase())) ? 1 :
                ((a.value && b.value) && (b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0)
          )
          .map(_m => _m.value);
      }

      if (!this.credentialing_required_items_categories) {
        // get someone to create this dropdown
        this._sharedUtilsService.showMintNotificationWithOptions("Credentialing Required Items Categories dropdown is not set. Please have an admin do this.");
      }

      resolve(this.credentialing_required_items_categories);
    });
  }


  // updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
  //   const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
  //   return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/', JSON.stringify(updateRequest), this.headerOptions);
  // }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<CredentialingSetupsApi> {
    return this.httpClient.put<CredentialingSetupsApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  createChangeEntriesByBatch(_updateReq): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'required-items-batch-change-entries', JSON.stringify(_updateReq), this.headerOptions);
  }


  updateRequiredItemsByBatch(_updateReq): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'required-items-batch-update', JSON.stringify(_updateReq), this.headerOptions);
  }


  updateFavorites(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  normalSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'normal-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getRequest(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  getWithRelationships(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'with-relationships/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  itemsSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'items/search', JSON.stringify(searchTerms), this.headerOptions);
  }


  async createChangeEntry(_user, _module, _record, _previousValue, _newValue, _required_item, _subField = null) {
    const fakeField = new LayoutField();

    let fieldLabel = 'Required Item - ' + _required_item.label;
    if (_subField) fieldLabel += ` - ${_subField}`;
    fakeField.label = fieldLabel;

    fakeField.fieldName = _required_item._id;
    fakeField.type = {
      inputType: 'TextField',
      fieldType: 'TextField',
      schemaType: 'String'
    };

    const auditEntry = new AuditLog();
    auditEntry.changed_by = _user._id;
    auditEntry.module = _module.name.toLowerCase();
    auditEntry.fullModule = _module._id;
    auditEntry.changeSource = 'direct record';
    auditEntry.fullField = fakeField;
    auditEntry.record_id = _record._id;
    auditEntry.record_name = _record.name;
    auditEntry.field = _required_item._id;
    auditEntry.previous_value = _previousValue;
    auditEntry.new_value = _newValue;

    await lastValueFrom(this._auditService.create(auditEntry));
  }


  checkForChanges(original, changed): Promise<any[]> {
    return new Promise(async (resolve) => {
      const changesDetected = [];

      const fieldsToCheck = Object.keys(original);

      fieldsToCheck.forEach(_f => {
        console.log('Checking field: ', _f);

        let originalVal = (original[_f] && original[_f] != 'undefined') ? original[_f] : null;
        let changedVal = (changed[_f] && changed[_f] != 'undefined') ? changed[_f] : null;

        console.log('original[fieldName]: ', originalVal);
        console.log('changed[fieldName]: ', changedVal);

        if (changedVal && typeof changedVal === 'object') {
          const objectFields = Object.keys(changedVal);
          console.log('Object fields to check: ', objectFields);

          objectFields.forEach(_of => {
            let ov = (original[_of] && original[_of] != 'undefined') ? original[_of] : null;
            let cv = (changed[_of] && changed[_of] != 'undefined') ? changed[_of] : null;

            if (ov !== cv) {
              changesDetected.push({ field: _f + ' - ' + _of, previous_value: ov, new_value: cv });
            }
          });
        } else {
          // normal field, just check value
          if (originalVal !== changedVal) {
            changesDetected.push({ field: _f, previous_value: originalVal, new_value: changedVal });
          }
        }
      });

      // console.log('changesDetected: ', changesDetected);

      resolve(changesDetected);
    });
  }


  selectAllSearch(searchTerms): Observable<CredentialingSetupsApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<CredentialingSetupsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CredentialingSetupsApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CredentialingSetupsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }
}