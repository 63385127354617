import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
// import { CredentialingActivity, User } from '@app/models';
import { CredentialingChecklistItem } from '@app/modules/credentialing/credentialing-setups/credentialing-setups.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

export interface CredentialingRequiredItemsApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CredentialingRequiredItemsService {
  private currentRequiredItem;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/credentialing-required-items/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_requiredItem): void {
    this.currentRequiredItem = _requiredItem;
  }

  getCurrent() {
    return this.currentRequiredItem;
  }

  create(_requiredItem: CredentialingChecklistItem): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_requiredItem), this.headerOptions);
  }


  saveMultiple(_requiredItems: CredentialingChecklistItem[]): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'saveMultiple', JSON.stringify(_requiredItems), this.headerOptions);
  }


  update(_requiredItem: CredentialingChecklistItem): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _requiredItem._id, JSON.stringify(_requiredItem), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  normalSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'normal-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCredentialingItemRecord(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  markDeleted(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + id);
  }


  selectAllSearch(searchTerms): Observable<CredentialingChecklistItem[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<CredentialingChecklistItem[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CredentialingRequiredItemsApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CredentialingRequiredItemsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  createRequiredItems(_itemsToCreate: CredentialingChecklistItem[], _credentialingRecordId): Promise<any> {
    return new Promise(async (resolve) => {

      let createdItems = [];

      if (_itemsToCreate?.length && _credentialingRecordId) {
        const defaultDetails = {
          expirationDate: null,
          approvalStatus: '',
          documentLink: null,
          actionValue: null,
          notes: '',
          dateCompleted: null
        }

        const preparedItems = [];

        for (const _item of _itemsToCreate) {
          // console.log('Creating required item from item: ', _item);

          const newRequiredItem = new CredentialingChecklistItem();
          newRequiredItem.deleted = false;
          newRequiredItem.name = _item?.label ?? 'Unknown';
          newRequiredItem.label = _item?.label ?? 'Unknown';
          newRequiredItem.category = _item?.category ?? null;
          newRequiredItem.provider_assigned = _item?.provider_assigned ?? false;
          newRequiredItem.description = _item?.description ?? '';
          newRequiredItem.completed = _item?.completed ?? false;
          newRequiredItem.completionDetails = _item?.completionDetails ?? defaultDetails;
          newRequiredItem.field_type = _item?.field_type ?? null;
          newRequiredItem.field_options = _item?.field_options ?? null;
          newRequiredItem.provider_type = _item?.provider_type ?? null;
          newRequiredItem.relatedCredentialingRecord = _credentialingRecordId;
          newRequiredItem.unreadMessageCount = 0; // Only used for display.
          newRequiredItem.messages = []; // Virtual array.
          // console.log('Create Item: ', newRequiredItem);

          preparedItems.push(newRequiredItem);
        }

        const savedItems = await lastValueFrom(this.saveMultiple(preparedItems)); // Send the prepared items to the backend in one call
        createdItems = (savedItems?.length) ? savedItems : [];
        // console.log('Saved Items: ', savedItems);
      }

      resolve(createdItems);
    });
  }
}