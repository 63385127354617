import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';

import { cacheable } from '@app/utils/rxjs-functions';

import { Dropdown } from '@app/models';
import { ExpiringCache } from '@app/models/expiringCache';
import { DropdownService } from '@app/services';
import { SharedUtilsService } from '@app/services/shared-utils.service';

export interface InteractiveDocumentsApi {
  items: any[];
  total_count: number;
}

export class DocFileResponse {
  file?: File;
  error?: string

  constructor(_file?: File, _error?: string) {
    this.file = _file;
    this.error = _error;
  }
}

export class PdfInput {
  type: string;
  field;
  assigned_to;
  recipient_id;
  top: number;
  left: number;
  width: number;
  height: number;
  systemFieldName: string;
  options;

  constructor(_type: string, _top: number, _left: number, _width: number, _field) {
    this.type = _type;
    this.top = _top;
    this.left = _left;
    this.width = _width;
    this.field = _field;
    this.height = 25;
  }
}

@Injectable({ providedIn: 'root' })
export class InteractiveDocumentsService {
  private currentDocument;
  private API_URL = '/api/interactive-documents/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  documentsStorage: ExpiringCache = new ExpiringCache();


  recipients = [
    { label: 'Sender (Prefill)', color: '#FF1AB8'},
    { label: 'Participant 1', color: '#837200'},
    { label: 'Participant 2', color: '#167EFB'},
    { label: 'Participant 3', color: '#232308'},
    { label: 'Participant 4', color: '#FF753E'},
    { label: 'Participant 5', color: '#8E37F1'},
    { label: 'Participant 6', color: '#139CDB'},
    { label: 'Participant 7', color: '#10C78A'},
    { label: 'Participant 8', color: '#0140B4'},
    { label: 'Participant 9', color: '#FF5100'},
    { label: 'Participant 10', color: '#A200FF'},
    { label: 'Participant 11', color: '#FFB703'},
    { label: 'Participant 12', color: '#99000D'},
    { label: 'Participant 13', color: '#5CE402'},
    { label: 'Participant 14', color: '#66008F'},
    { label: 'Participant 15', color: '#D185F5'},
    { label: 'Anyone', color: '#A3640D'},
    { label: 'Everyone', color: '#005700'}
  ];


  public currentSearch = {
    params: null,
    results: null
  };

  constructor(
    private httpClient: HttpClient,
    private _dropdownService: DropdownService,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrent(_document): void {
    this.currentDocument = _document;
  }


  getCurrent() {
    return this.currentDocument;
  }


  create(_document): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_document), this.headerOptions);
  }


  generateImagesFromDocument(_docDetails): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'generate-images-from-url', JSON.stringify(_docDetails), this.headerOptions);
  }


  createMergedPDFFromDocuments(_pdfsToMerge: string[], _titleForNewPdf: string): Observable<any> {
    const _mergeDetails = { pdfArray: _pdfsToMerge, titleForPdf: _titleForNewPdf };
    return this.httpClient.post<any>(this.API_URL + 'create-merged-pdf', JSON.stringify(_mergeDetails), this.headerOptions);
  }


  getCached(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.documentsStorage)) {
      // console.log('Saved an API call for locations. Retreived from cache.');
      return this.documentsStorage.itemCache;
    }

    this.documentsStorage = this._sharedUtilsService.updateStorageExpiration(this.documentsStorage);
    // console.log('Set locations storage to: ', this.locationsStorage);
    return this.documentsStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<Document[]> {
    return this.httpClient.put<Document[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  updateFavorites(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  getDocument(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  retrieveDocumentFileAsBlob(_path: string): Observable<Blob | { error: string }> {
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/octet-stream'}), // Need to tell it to accept a blob in the response.
      responseType: 'blob' as const, // Requesting Blob but need to check contents for custom errors. Must use as const or type errors happen.
    };

    // Need to pipe and map or even custom errors are returned as a blob.
    return this.httpClient.post(this.API_URL + 'retrieve-file-buffer/by-path', { path: _path }, options).pipe(
      switchMap(async (response: Blob) => {
        // Attempt to read the Blob as JSON in case a custom object with error was returned.
        if (response?.type === 'application/json') {
          try {
            const json = JSON?.parse(await response?.text());
            return { error: json?.error || 'Unknown error occurred.' };
          } catch {
            return { error: 'Failed to parse error response.' };
          }
        } else {
          return response; // There was no custom error so return valid Blob.
        }
      }),
      catchError(_err => of({ error: _err?.message || 'Failed to retrieve document file.' }))
    );
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAllSearch(_moduleOptions): Observable<Document[]> {
    // searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<Document[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(_moduleOptions): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getDataSource(_moduleOptions, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<InteractiveDocumentsApi> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;

    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize; 

    return this.httpClient.post<InteractiveDocumentsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions); 
  }


  retrieveDocumentFile(_path: string): Promise<DocFileResponse> {
    return new Promise(async (resolve) => {
      try {
        const blobRetrievalResult = await lastValueFrom(this.retrieveDocumentFileAsBlob(_path));
        // console.log('Blob retrieval results:', blobRetrievalResult);

        let fileResponse = new DocFileResponse();

        // If it's a Blob, convert it to a File object. Otherwise, if there's an error in the response resolve it or unexpected result format.
        if (blobRetrievalResult instanceof Blob) {
          const fileName = _path?.split('/')?.pop(); // Extract the file name from the path.
          const mimeType = this.getMimeTypeFromFileName(fileName); // Get actual type from file name to use in converting to file.
          fileResponse.file = new File([blobRetrievalResult], fileName, { type: mimeType }); // Convert to file object.
        } else {
          const errorMessage = blobRetrievalResult?.error ?? 'Unexpected response format.';
          console.log('Error retrieving file:', errorMessage);
          fileResponse.error = errorMessage; // Return results error.
        }
        resolve(fileResponse);
      } catch (_err) {
        // Catch any other errors that may occur during the process
        console.log('Error retrieving document file:', _err);
        resolve(new DocFileResponse(null, 'Error retrieving document file.'));
      }
    });
  }


  getMimeTypeFromFileName(filename) {
    const extension = filename?.split('.')?.pop()?.toLowerCase();
    const mimeTypes = {
      pdf: 'application/pdf',
      png: 'image/png',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      gif: 'image/gif',
      txt: 'text/plain'
    };
    return mimeTypes[extension] || 'application/octet-stream'; // Default to binary/octet stream if not found
  }


  adjustDocumentPageInputs(_chosenTemplate, _yeehroDropdowns?: Dropdown[]) {
    return new Promise(async (resolve) => {
      // Needed for dropdown cases with related_dropdown.
      if (!_yeehroDropdowns?.length) _yeehroDropdowns = await lastValueFrom(this._dropdownService.search({ deleted: false }));

      if (_chosenTemplate?.doc_data?.pages?.length) {
        for (const _page of _chosenTemplate.doc_data.pages) {
          for (const _input of _page?.inputs || []) {
            // Certain input fields may need adjustments like dropdowns.
            this.makeInputAdjustments(_input, _yeehroDropdowns);
          }
        }
      }

      resolve(null);
    });
  }


  makeInputAdjustments(_input: any, _yeehroDropdowns: Dropdown[]) {
    // If dropdown needs certain things done.
    if (_input?.type?.inputType === 'Dropdown') {
      // See if there is a related dropdown and if behavior is use. Dropdown options need to be updated to current in this case.
      if (_input.field?.related_dropdown) {
        // Make sure it is only a string and was not saved populated since we don't want to entire dropdown.
        if (_input.field?.related_dropdown?._id) _input.field.related_dropdown = _input.field?.related_dropdown?._id

        if (_input.field?.systemDropdownBehavior === 'use') {
          // Find the related dropdown from ones retrieved outside loop. Compare as strings incase it tries ObjectId to string comparison.
          const matchingDropdown = _yeehroDropdowns?.find(_d => _d?._id?.toString() === _input.field.related_dropdown?.toString());
          if (matchingDropdown != undefined) {
            // If found, update the options with current options from db.
            const dropdownValues = (matchingDropdown?.options ?? []).filter(_option => _option?.value).map(_option => _option.value);
            _input.field.options = dropdownValues;
            console.log('Updating dropdown options with current options.');
          }
        }
      }
    }

    return _input;
  }


  addParticipant(_currentRecipients: any[]) {
    const availableParticipants = [...this.recipients].filter(_r => _r?.label?.includes('Participant'));
    const currentParticipants = _currentRecipients?.filter(_r => _r?.label?.includes('Participant'));
    // console.log('Available Participants: ', availableParticipants);
    // console.log('Current Participant: ', currentParticipants);

    const newParticipantToAdd = availableParticipants[currentParticipants.length];
    _currentRecipients.splice(currentParticipants.length + 1, 0, newParticipantToAdd);
  }


  getCorrectedPageInputs(_pageInputs: any[] = [], _recipients: any[], _yeehroDropdowns: Dropdown[]): Promise<any[]> {
    return new Promise(async (resolve) => {
      if (!_pageInputs?.length) _pageInputs = [];

      for (const _input of _pageInputs) {
        const recipientLabels = _recipients?.map(r => r?.label) ?? []; // Updated on each loop

        // Certain fields need adjusting like dropdown fields with related_dropdown use cases.
        this.makeInputAdjustments(_input, _yeehroDropdowns);

        let recipientIdToAssign = 0;
        let assignedTo = _input?.assigned_to ?? _input?.field?.assigned_to;

        // Handle assignment cases without mutating assignedTo
        if (!assignedTo?.length || assignedTo.toLowerCase() === 'signer') {
          assignedTo = 'Participant 1';
        } else if (assignedTo.toLowerCase() === 'approver') {
          assignedTo = 'Sender (Prefill)';
        } else if (!recipientLabels.includes(assignedTo)) {
          let currentRecipientId = _input?.recipient_id ?? _input?.field?.recipient_id ?? _input?.field?.recipientId ?? 0;
          currentRecipientId = Math.min(currentRecipientId + 1, 15); // Cap at 15 participants
          assignedTo = `Participant ${currentRecipientId}`;
        }

        // Add participant if missing and update recipientLabels next iteration
        if (!_recipients.some(r => r?.label === assignedTo)) this.addParticipant(_recipients);

        // Retained for future correction if foundRecipientIndex is -1
        const foundRecipientIndex = _recipients.findIndex(r => r?.label === assignedTo);
        recipientIdToAssign = (foundRecipientIndex > -1) ? foundRecipientIndex : 1; // If not found just assign Participant 1 index.

        // Ensure input field exists so we can add changes to it. If not create new field with basic attributes.
        if (_input?.field == undefined) {
          const _systemFieldName = 'field-' + Date.now() + Math.random();

          _input.field = {
            formatting: 'Text',
            fromModule: null,
            label: 'New Field',
            name: 'Text Input',
            readOnly: false,
            required: false,
            systemFieldName: _systemFieldName.replace('.', '-'),
            type: { inputType: "TextField" }
          };
        }

        // Assign corrected assigned to.
        _input.assigned_to = assignedTo;
        _input.field.assigned_to = assignedTo;

        // Assign corrected recipient ID.
        _input.recipient_id = recipientIdToAssign;
        _input.field.recipient_id = recipientIdToAssign;
        _input.field.recipientId = recipientIdToAssign;
      }

      resolve(_pageInputs);
    });
  }
}