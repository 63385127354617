import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

export interface ApplicantsApi {
  items: [];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ApplicantService {
  private currentApplicantBeingViewed;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/applicants/';

  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrentBeingViewed(_applicant): void {
    this.currentApplicantBeingViewed = _applicant;
  }


  getCurrentBeingViewed() {
    return this.currentApplicantBeingViewed;
  }


  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL);
  }


  getById(id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + id);
  }
S

  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  checkForSuccessfulApplication(searchTerms): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'application-check', JSON.stringify(searchTerms), this.headerOptions);
  }


  replayApplication(_applicantInfo): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'application-replay', JSON.stringify(_applicantInfo), this.headerOptions);
  }


  doCourseSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'title', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<ApplicantsApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<ApplicantsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}